import { Drawer, FormControlLabel, Switch } from "@material-ui/core"
import { WoodenSpoon as WoodenSpoonContext, State } from '@contexts';
import { useContext } from "react";

export const SideNav = () => {
	const { tags } = useContext(WoodenSpoonContext);
	const { sideNavOpen, setSideNavOpen, filters, setFilters } = useContext(State);

	return <Drawer open={sideNavOpen} onClose={() => setSideNavOpen(false)}>
		<div style={{ padding: '20px', display: 'flex', flexDirection: 'column'}}>
			{tags.map((tag) => (
				<FormControlLabel
					label={tag}
					style={{ textTransform: 'capitalize' }}
					control={
						<Switch
							key={tag} checked={filters.includes(tag)}
							onChange={(event) => {
								if (!event.currentTarget.checked) setFilters(filters.filter((f) => f !== tag))
								else if (!filters.includes(tag)) setFilters([...filters, tag]);
							}}
						/>
					}
				/>
			))}
		</div>
	</Drawer>
}
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import { Login as DefaultLogin } from './login';

const AuthListener = ({
    context: Context,
    requireAuth,
    children,
    login: Login,
}) => {
    const [ user, setUser ] = useState(null);

    useEffect(() => {
        onAuthStateChanged(getAuth(), user => {
            if (user) {
              setUser(user);
            } else {
              setUser(null);
            }
        });
    });

    if (!user && requireAuth) {
        return (
            (Login ? <Login /> : <DefaultLogin />)
	    );
    }

    return (
        <Context.Provider value={{
            user,
            signOut: signOut
        }}>{ children }</Context.Provider>
    );
}

export default AuthListener;
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Chip, Dialog, DialogActions, DialogContent, FormControlLabel, Switch, TextField, capitalize } from '@material-ui/core';

import ImageSelector from '@components/ImageSelector';
import SelectOrCreate from '@components/SelectOrCreate';
import { Select as SelectUnit } from '@components/WoodenSpoon/Unit';
import { Auth, Firebase, WoodenSpoon as WoodenSpoonContext } from '@contexts';

import './edit.less';
import { Column, Row } from '@components/Layout';
import { useMediaVariants } from 'hooks/mediaVariants';

const RecipeEditDialog = ({
	open,
	onClose,
	onSave,
	onDelete,
	recipe,
}) => {
	const { isMobile } = useMediaVariants();
	const [ data, setData ] = useState({});
	const history = useHistory();
	const { recipes, tags } = useContext(WoodenSpoonContext);
	const { storage } = useContext(Firebase);
	const { user } = useContext(Auth);

	useEffect(() => {
		setData({
			...recipes[recipe]
		});
	}, [recipes, recipe, setData]);

	const save = () => {
		onSave(data);
		setData(data);
		onClose();
	}

	return (
		<Dialog
			className="recipe-edit"
			open={open}
			onClose={onClose}
			onKeyPress={(event) => { if (event.key === 'Enter') save() }}
			fullScreen={ isMobile }
		>
			<DialogContent>
				<Row collapse={ isMobile } style={{ maxWidth: '100%' }}>
					<Column centred style={{ flex: 0, gap: 0 }}>
						<ImageSelector
							path={`/${user?.uid}/recipes/${recipe}`}
							storage={storage}
							onChange={ (e) => {
								setData({
									...data,
									image: e
								});
							}}
							value={ data.image }
							width={ 200 }
							height={ 200 }
							maxSize={ [1024,1024] }
						/>
						<FormControlLabel
							label={ data.shared ? 'Public' : 'Private' }
							control={
								<Switch
									checked={ data.shared }
									onChange={ (e) => {
										setData({
											...data,
											shared: !data.shared
										})
									}}
								/>
							}
						/>
					</Column>
					<Column>
						<TextField
							value={ data.name }
							onChange={ (e) => {
								setData({
									...data,
									name: e.target.value
								})
							}}
							label="Name"
							variant="outlined"
							fullWidth
						/>
						<TextField
							value={ data.source }
							onChange={ (e) => { setData({
								...data,
								source: e.target.value
							})}}
							label="Source"
							variant="outlined"
							fullWidth
						/>
						<Row style={{ flex: 0 }}>
							<TextField
								value={ data.servings }
								onChange={ (e) => { setData({
									...data,
									servings: e.target.value
								})}}
								label="Servings"
								variant="outlined"
								className="serving"
								fullWidth
							/>
							<SelectUnit
								value={ data.servings_unit }
								onChange={ (val) => {
									setData({
										...data,
										servingsUnit: val
									})
								}}
							/>
						</Row>
						<SelectOrCreate
							options={ tags.map((t) => ({ name: capitalize(t), value: t })) }
							label="Tags"
							className="tag-select"
							onChange={(idx) => {
								if (!tags[idx]) return;
								setData({
									...data,
									tags: [...data.tags ?? [], tags[idx]]
								})
							}}
							onCreate={async (value) => {
								setData({
									...data,
									tags: [...data.tags ?? [], value]
								})
							}}
							fullWidth
						/>
						<div className="tags" style={{ display: 'flex', gap: '0.5rem' }}>{data.tags?.map((t) =>
							<Chip label={t} key={t} style={{ textTransform: 'capitalize' }}  onDelete={() => setData({ ...data, tags: data.tags.filter((_t) => t !== _t) })} />
						)}</div>
					</Column>
				</Row>
			</DialogContent>
			<DialogActions>
				{ onDelete && (
					<Button
						className="error"
						variant="contained"
						onClick={ () => {
							onDelete();
							onClose();
							history.push('/');
						 }}
					>
						Delete
					</Button>
				)}
				<Button onClick={ onClose }>Cancel</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => save()}
				>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RecipeEditDialog;
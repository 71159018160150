const firebase = {
	apiKey: "AIzaSyAJX-XUemgv3aXh4SdOCx4dY4nYHVf44zM",
	authDomain: "savour-182ed.firebaseapp.com",
	databaseURL: "https://savour-182ed.firebaseio.com",
	projectId: "savour-182ed",
	storageBucket: "savour-182ed.appspot.com",
	messagingSenderId: "323193342831",
	appId: "1:323193342831:web:1d18ede3838d40b2"
}

export default firebase;
export { firebase };

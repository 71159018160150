import React, { useContext, useState } from 'react';
import slugify from 'slugify';
import { useHistory } from 'react-router-dom';

import { AppBar, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { 
	Add as AddIcon,
	Menu as MenuIcon
} from '@material-ui/icons';

import { 
	Auth as AuthContext,
	Firebase as FirebaseContext,
	State as StateContext,
} from '@contexts';
import { Login, Logout } from '@components/Firebase';
import { Edit as RecipeEditDialog } from '@components/WoodenSpoon/Recipe';

import './site-header.less';
import { setDoc } from 'firebase/firestore';
import { Row } from '@components/Layout';
import { Close } from '@mui/icons-material';
import { useMediaVariants } from 'hooks/mediaVariants';


const SiteHeader = () => {
	const { isMobile } = useMediaVariants();
	const { user } = useContext(AuthContext);
	const { doc } = useContext(FirebaseContext);
	const { search, setSearch, setSideNavOpen } = useContext(StateContext);
	const [ adding, setAdding ] = useState(false);
	const history = useHistory();

	return (
		<AppBar position="static">
			<Toolbar>
				<Row style={{ alignItems: 'space-between'}}>
					<Row style={{ flex: isMobile ? 0 : 1 }} centred>
						<IconButton onClick={ () => setSideNavOpen(true) }>
							<MenuIcon />
						</IconButton>
						<Typography className="title">
							Wooden Spoon
						</Typography>
					</Row>
					<Row style={{ maxWidth: '23em', justifyContent: 'end' }} centred>
						<div style={{ position: 'relative', flex: 1 }}>
							<TextField
								variant="outlined"
								className="search"
									placeholder="Search"
									style={{ width: '100%', padding: 0 }}
								value={ search || '' }
								onChange={ (e) => setSearch(e.target.value) }
							/>
							{search !== '' && (
								<IconButton
									style={{ fontSize: '0.5em', padding: '0.2rem', position: 'absolute', top: '1em', right: '0.75em', borderRadius: '50%', backgroundColor: 'rgba(255,255,255,0.3)' }}
									onClick={() => setSearch('')}
								>
									<Close style={{ width: '1rem', height: '1rem' }} />
								</IconButton>
							)}
						</div>
						{user ? (
							<>
								<IconButton
									style={{ fontSize: '0.5em' }}
									onClick={() => setAdding(true)}
								>
									<AddIcon />
								</IconButton>
								<Logout />
							</>
						) : (
							<Login />
						)}
					</Row>
				</Row>
			</Toolbar>
			<RecipeEditDialog
				open={ adding }
				onClose={ () => setAdding(false) }
				onSave={ async (data) => {
					const link = data.link ?? slugify(data.name).toLowerCase();
					await setDoc(doc('recipes', link), {
						...data,
						owner: user.uid,
					});
					history.push(`/recipe/${link}`);
				}}
			/>
		</AppBar>
	);
};

export default SiteHeader;
import React, { useContext, useEffect, useState } from 'react';
import {
	Button, Dialog, DialogActions, DialogContent,
	FormControlLabel, MenuItem, Switch, TextField
} from '@material-ui/core';

import {
	WoodenSpoon as WoodenSpoonContext,
} from '@contexts';

import './edit.less';
import { useMediaVariants } from 'hooks/mediaVariants';

const StepDialog = ({
	open,
	onClose,
	component,
	components,
	componentStartAt,
	componentNumber,
	text,
	time,
	holdable,
	active,
	onSave,
	onDelete,
}) => {
	const [data, setData] = useState({});
	const { recipes } = useContext(WoodenSpoonContext);
	const { isMobile } = useMediaVariants();

	useEffect(() => {
		setData({
			component: component || '',
			componentStartAt: componentStartAt || '',
			componentNumber: componentNumber || '',
			text: text || '',
			time: time || '',
			holdable: holdable || '',
			active: active || '',
		});
	}, [setData, component, componentStartAt, componentNumber, text, time, holdable, active, open]);

	return (
		<Dialog
			className="step edit"
			open={open}
			onClose={onClose}
			onKeyPress={(event) => { if (event.key === 'Enter') { onSave(data); onClose(); } }}
			fullScreen={ isMobile }
		>
			<DialogContent style={{ alignContent: 'start' }}>
				<TextField
					className="text"
					value={ data.text }
					onChange={ (e) => setData({
						...data,
						text: e.target.value
					})}
					label="Text"
					variant="outlined"
					fullWidth
					multiline
				/>
				{!data.text && components && components.length > 0 && (
					<>
						<p>OR</p>
						<TextField
							select
							className="component"
							value={ data.component }
							onChange={ (e) => setData({
								...data,
								component: e.target.value
							})}
							label="Component"
							variant="outlined"
							fullWidth
						>{
							components.map((c, idx) => (
								<MenuItem key={ idx } value={ c }>{ recipes[c].name }</MenuItem>
							))
							}</TextField>
					</>
				)}
				{ (!components || components.length === 0 || data.text) && (<>
					<TextField
						value={ data.time }
						inputProps={{ type: 'number' }}
						onChange={ (e) => { setData({
							...data,
							time: e.target.value
						})}}
						label="Duration"
						variant="outlined"
						className="time"
						fullWidth
					/>
					<TextField
						value={ data.hold }
						inputProps={{ type: 'number' }}
						onChange={ (e) => { setData({
							...data,
							hold: e.target.value
						})}}
						label="Can be held for"
						variant="outlined"
						className="hold"
						fullWidth
					/>
					<FormControlLabel
						label={ active ? 'Active' : 'Passive' }
						control={
							<Switch
								checked={ Boolean(active) }
								onChange={ (e) => {
									setData({
										...data,
										active: e.target.value
									})
								}}
							/>
						}
					/>
				</>)}
			</DialogContent>
			<DialogActions>
				<Button onClick={ onClose }>Cancel</Button>
				<Button
					className="error"
					variant="contained"
					onClick={() => {
						onDelete();
						onClose();
					}}
				>Delete</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={ () => {
						onSave(data);
						onClose();
					}}  
				>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default StepDialog;
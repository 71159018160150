import React, { useContext, useState } from 'react';

import { WoodenSpoon as WoodenSpoonContext } from '@contexts';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';

import { getQuantity } from '@utils';
import IngredientGroup from './group';
import IngredientEditDialog from './edit';
import './view.less';

const Ingredient = ({
	id,
	quantity,
	unit,
	preparation,
	editable,
	group,
	onChange,
	onDelete,
	dndProps,
	multiplier
}) => {
	const { recipes, ingredients, units } = useContext(WoodenSpoonContext);
	const [editing, setEditing] = useState(false);
	
	if (!recipes || !ingredients || !units) return null;

	if (recipes[id]?.ingredients) {
		return (
			recipes[id].ingredients.map(({ group, items }) => {
				return (<>
					<IngredientGroup
						key={group}
						dndProps={editable ? dndProps : null}
						prefix={recipes[id].name}
						title={group}
						quantity={quantity * multiplier}
						unit={recipes[id].servingsUnit}
						ingredients={items}
						multiplier={multiplier * (quantity / recipes[id]?.servings ?? 1)}
						link={`/recipe/${recipes[id]}`}
						onClick={ () => setEditing(true) }
					/>
					{ editable && <IngredientEditDialog
						open={ editing }
						onClose={ () => setEditing(false) }
						quantity={ quantity }
						unit={ unit && unit.id }
						ingredient={ id }
						preparation={preparation}
						group={group}
						multiplier={multiplier}
						onSave={(data) => { onChange(data) }}
						onDelete={onDelete}
					/>}
				</>)
			})
		);
	}

	return <li className={`ingredient ${editable && 'editable'}`} ref={dndProps?.innerRef} {...dndProps?.draggableProps}>
		{dndProps && (<div {...dndProps?.dragHandleProps}><DragIndicatorIcon /></div>)}
		<span onClick={(e) => editable && setEditing(true)}>
			{ getQuantity(quantity, units[unit?.id], multiplier) }
			{' '}
			{ recipes[id]?.name || ingredients[id]?.name || id }
			{ preparation && `, ${preparation}` }
		</span>
		{ editable && <IngredientEditDialog
			open={ editing }
			onClose={ () => setEditing(false) }
			quantity={ quantity }
			unit={ unit && unit.id }
			ingredient={ id }
			preparation={preparation}
			group={group}
			onSave={onChange}
			onDelete={onDelete}
		/>}
	</li>;
}

export default Ingredient;
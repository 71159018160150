export const Column = ({ children, centred, style: _style }) => {
	const variants = {
		centred: { alignItems: 'center' }
	}

	const style = {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		gap: '1rem',
		...(centred ? variants.centred : {}),
		..._style
	}

	return (
		<div style={style}>
			{children}
		</div>
	)
}

export const Row = ({ children, centred, collapse, style: _style }) => {
	const variants = {
		centred: { alignItems: 'center' }
	}

	const style = {
		display: 'flex', gap: '1rem', flex: 1,
		flexDirection: collapse ? 'column' : 'row',
		...(centred ? variants.centred : {}),
		..._style
	}
	
	return (
		<div style={style}>
			{ children}
		</div>
	)
}

export const Grid = ({ children, style }) => (
	<div style={{ display: 'grid', gap: '1rem', ...style }}>{children}</div>
)
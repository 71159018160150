import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { LoginOutlined as LoginIcon } from '@mui/icons-material';

export const Login = () => (
    <IconButton
        onClick={() => {
            const provider = new GoogleAuthProvider();
            signInWithPopup(getAuth(), provider)
        }}
    >	  
        <LoginIcon />
    </IconButton>
)
import React from 'react';

import {
	Menu as MenuIcon,
} from '@material-ui/icons';

import './thumb-menu.less'

const ThumbMenu = ({ children, open, onChange }) => {
    const ref = React.createRef()

    return (
        <div className="thumbmenu" ref={ ref }
            onWheel={ (e) => {
                const dir = e.nativeEvent.deltaY / Math.abs(e.nativeEvent.deltaY)
                let left = ref.current.scrollLeft + (192 * dir)
                left = left - (left % 192)
                ref.current.scrollLeft = left
            }}
        >
        <div className="grip" onClick={ (e) => {
            onChange(!open);
            e.stopPropagation()
        }}><MenuIcon /></div>
            { children }
        </div>
    );
};

export default ThumbMenu;
export { ThumbMenu };
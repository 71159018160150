import { initializeApp } from 'firebase/app';
import React, { useContext, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';
import { getStorage } from 'firebase/storage';
import { collection, doc, getFirestore, persistentLocalCache, persistentMultipleTabManager, query, where } from 'firebase/firestore';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { AuthListener, QueryListener } from '@components/Firebase';
import {
	Auth as AuthContext,
	Firebase as FirebaseContext,
	WoodenSpoon as WoodenSpoonContext,
	State as StateContext,
} from '@contexts';
import { firebase as firebaseCredentials } from '@credentials';

import Routes from './routes';
import RecipeNav from './recipe-nav';
import SiteHeader from './site-header';
import { SideNav } from './side-nav';
import './woodenspoon.less';

initializeApp(
	firebaseCredentials, 
	{localCache: 
		persistentLocalCache({tabManager: persistentMultipleTabManager()})
	}
);

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#66a832',
			contrastText: '#ffffff',
		},
		error: {
			main: '#b71c1c',
			contrastText: '#ffffff',
		},
		text: {
			main: '#ffffff',
			contrastText: '#000000',
		}
	}
});

const WoodenSpoon = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState([]);
	const [sideNavOpen, setSideNavOpen] = useState(false);

	return (
		<div className={ 'woodenspoon '  + (drawerOpen ? '' : 'drawerClosed') }>
				<BrowserRouter>
					<StateContext.Provider value={{ search, setSearch, filters, setFilters, sideNavOpen, setSideNavOpen }}>
					<SiteHeader />
						<SnackbarProvider maxSnack={3}>
						
							<div className="viewport">
								<SideNav />
								<Routes />
							</div>
						</SnackbarProvider>
						<RecipeNav open={drawerOpen} onChange={setDrawerOpen} />
					</StateContext.Provider>
				</BrowserRouter>
		</div>
	);
}

const FirebaseWrapper = () => {
	const { user } = useContext(AuthContext);
	const { collection } = useContext(FirebaseContext);
	const recipeQ = []
	if (user?.uid) {
		recipeQ.push(query(collection('recipes'), where('shared', '==', true), where('owner', '!=', user.uid)));
		recipeQ.push(query(collection('recipes'), where('owner', '==', user.uid)));
	} else {
		recipeQ.push(query(collection('recipes'), where('shared', '==', true)));
	}

	return (
		<QueryListener
			context={WoodenSpoonContext}
			queries={{
				recipes: recipeQ,
				ingredients: collection('ingredients'),
				units: collection('units'),
			}}
			transform={{
				recipes: {
					recipes: (data) => {
						const recipes = Object.fromEntries(
							Object
								.entries(data.recipes ?? {})
								.map(([id, a]) => [id, {
									...a,
									ingredients: (a.ingredients ?? []).map((group) => ({
										...group,
										items: group.items.map((item) => ({
											...item,
											quantity: typeof item.quantity === 'string' ? parseFloat(item.quantity) : item.quantity
										}))
									}))
								}])
						);
						return recipes;
					},
					tags: (data) => {
						const tags = Object.values(data.recipes)
							.flatMap((r) => r.tags ?? [])
							.filter((v, i, self) => self.indexOf(v) === i)
						return tags;
					}
				}
			}}
		>
			<WoodenSpoon />
		</QueryListener>
	);
}

const WoodenSpoonWrapper = () => {

	return (
		<ThemeProvider theme={ theme }>
			<FirebaseContext.Provider value={{
				doc: (path, id) => doc(getFirestore(), path, id),
				collection: (path) => collection(getFirestore(), path),
				storage: getStorage(),
				getDbRef: (collectionName, obj) => {
					if (!obj) return null;
					if (obj.key) return doc(getFirestore(), collectionName, obj.key);
					if (obj.id) return doc(getFirestore(), collectionName, obj.id);
					return doc(getFirestore(), collectionName, obj);
				}
			}}>
				<AuthListener context={ AuthContext }>
					<FirebaseWrapper />
				</AuthListener>
			</FirebaseContext.Provider>
		</ThemeProvider>
	)
};

export default WoodenSpoonWrapper;
import React from "react";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';

import { Button, FormLabel, Paper } from "@material-ui/core";

import "./styles.less";
import { Photo, PhotoOutlined } from "@mui/icons-material";

class ImageSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value ? this.props.value : null,
			uploading: false,
		};
		this.file = React.createRef();
		this.storage = props.storage;
		this.path = props.path;
	}

	click() {
		this.file.current.click();
	}

	clear() {
		this.setState({ value: null, filename: "" });
		if (this.props.onChange) this.props.onChange(null);
	}

	change(e) {
		const reader = new FileReader();
		if (e.target.files.length === 0) return;
		this.setState({ uploading: true });

		if (this.props.maxSize) {
			reader.onload = (e) => {
				const canvas = document.createElement("canvas");
				canvas.style.display = 'none';
				const img = new Image();
				img.style.display = 'none';
				document.body.appendChild(canvas);
				img.addEventListener('load', () => {
					console.log('ONLOAD IMG')
					var ctx = canvas.getContext("2d");
					var canvasCopy = document.createElement("canvas");
					var copyContext = canvasCopy.getContext("2d");
					var ratio = 1;

					if (img.width > this.props.maxSize[0]) {
						ratio = this.props.maxSize[0] / img.width;
					} else if (img.height > this.props.maxSize[1]) {
						ratio = this.props.maxSize[1] / img.height;
					}
					canvasCopy.width = img.width;
					canvasCopy.height = img.height;
					copyContext.drawImage(img, 0, 0);

					canvas.width = img.width * ratio;
					canvas.height = img.height * ratio;
					ctx.drawImage(
						canvasCopy,
						0,
						0,
						canvasCopy.width,
						canvasCopy.height,
						0,
						0,
						canvas.width,
						canvas.height
					);
					canvas.toBlob((resized) => {
						this._change(resized);
						canvas.parentNode.removeChild(canvas);
						img.parentNode.removeChild(img);
					}, "image/jpeg", 0.7);
				});
				img.src = e.target.result;
				document.body.appendChild(img);
			}
			reader.readAsDataURL(e.target.files[0]);
		} else {
			reader.onload = (e) => {
				this._change(e.target.result);
			}
			reader.readAsArrayBuffer(e.target.files[0]);
		}
		this.setState({ filename: e.target.value });
	}

	async _change(imagedata) {
		const parts = this.state.filename.split('.');
		const storageRef = ref(this.storage, `${this.path}.${parts[parts.length - 1]}`);
		
		await uploadBytes(storageRef, imagedata, undefined, { cacheControl: 'public, max-age=3600'});
		const url = await getDownloadURL(storageRef);
		this.setState({ value: url, uploading: false });
		if (this.props.onChange) this.props.onChange(url);
	}

	render() {
		const {
			value,
			width,
			height,
			onChange,
			label,
			prompt,
			maxSize,
			...extra
		} = this.props;

		return (
			<div className="image-selector" style={{ width: this.state.value ? 'auto' : '100%'}} {...extra}>
				{label ? <FormLabel>{label}</FormLabel> : null}
				<Paper
					className={`image ${this.state.value ? "selected" : "empty"}`}
					elevation={9}
				>
					<input
						accept="image/*"
						multiple
						type="file"
						ref={this.file}
						onChange={(e) => this.change(e)}
						value={this.state.filename ? this.state.filename : ""}
					/>
					<div className="clicker" style={{ display: 'flex', alignItems: 'center' }} onClick={() => this.file.current.click()}>
						{this.state.uploading && <div className="uploading">Uploading...</div>}
						{this.state.value
							? <img src={this.state.value} alt="Selected" style={{ maxWidth: `${width}px`, maxHeight: `${height}px` }} />
							: <img src="/image-placeholder.svg" style={{ flex: 1 }} />
						}
					</div>
				</Paper>
				<Button onClick={() => this.clear()}>Clear</Button>
			</div>
		);
	}
}

export default ImageSelector;
import React, { useContext, useState } from 'react';

import { WoodenSpoon as WoodenSpoonContext } from '@contexts';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';

import StepEditDialog from './edit';

import './view.less';

const Step = ({
	step,
	editable,
	onChange,
	onDelete,
	dndProps,
}) => {
	const [ editing, setEditing ] = useState(false);
	const { recipes } = useContext(WoodenSpoonContext);

	if (step.component && recipes[step.component.id]) {
		if (!editable) {
			return (
				<div className="group">
					<h2>{ recipes[step.component.id].name }</h2>
					{
						recipes[step.component.id].steps?.map((s, idx) => (
							<Step key={step.component + '-' + idx} step={s} />
						))
					}
				</div>
			)
		}
		
		return (
			<div className={`group ${editable && 'editable'}`} ref={dndProps?.innerRef} {...dndProps?.draggableProps}>
				<h2>
					{dndProps && (<span {...dndProps?.dragHandleProps}><DragIndicatorIcon /></span>)}
					{ recipes[step.component.id].name }
				</h2>
				{
					recipes[step.component.id].steps?.map?.((s, idx) => (
						<Step key={step.component + '-' + idx} step={s} />
					)) ??  null
				}
			</div>
		);
	}

	const style = {
		marginBottom: '1em',
		marginLeft: '1em'
	}
	
	return <li className={`step ${editable && 'editable'}`} style={style} ref={dndProps?.innerRef} {...dndProps?.draggableProps}>
		{dndProps && (<span {...dndProps?.dragHandleProps} className="drag-handle"><DragIndicatorIcon /></span>)}
		<span onClick={ (e) => editable && setEditing(true) }>
			{ step.text ? step.text : '<No Text>' }
		</span>
		{ editable && <StepEditDialog
			open={ editing }
			onClose={ () => setEditing(false) }
			component={ step.component }
			components={ step.components }
			componentStartAt={ step.componentStartAt }
			componentNumber={ step.componentNumber }
			text={ step.text }
			time={ step.time }
			holdable={ step.holdable }
			active={ step.active }
			onSave={onChange}
			onDelete={onDelete}
		/>}
	</li>;
}

export default Step;
export const mapObject = (obj, fn, skipFn) => {
    if (typeof obj === 'string') return obj;
    return Object.fromEntries(
        Object.entries(obj).
            map(([key, value]) => {
                if (Array.isArray(value)) {
                    return [
                        key,
                        value
                            .filter((v) => !skipFn(v))
                            .map((v2) => mapObject(v2, fn, skipFn))
                    ];
                } else if (typeof value === 'object'
                    && value !== null
                    && !skipFn?.(value)
                ) {
                    return [key, mapObject(value, fn, skipFn)];
                }
                return [key, fn(value)];
            })
    );
}

const fractions = {
	0.25: '¼',
	0.5: '½',
	0.75: '¾',
	0.33: '⅓',
	0.66: '⅔'
}

export const getQuantity = (quantity, unit, multiplier) => {
    let unitName = unit?.symbol ?? unit?.name ?? ' x';

	let q = Number.isFinite(quantity) ? Math.ceil(quantity * multiplier * 100) / 100 : 0;
	let whole = Math.floor(q);
	let frac = (q * 100 - whole * 100) / 100;
    if (fractions[frac]) frac = fractions[frac];
    else frac = Math.ceil(frac * 10) / 10;
    if (whole === 0 && frac === 0) unitName = '';
    if (whole === 0 && frac < 0.25 && !fractions[frac]) {
        // Downscale units
        console.log(`Downscale ${frac} ${unit?.name}`)
    }
    console.log(whole, frac);
	return `${(whole > 0 ? whole : '') + (frac !== 0 ? frac : '')}${unitName.length >= 2 ? ' ' : ''}${unitName}`;
}

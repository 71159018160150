import React, { useContext, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';

import { getQuantity } from '@utils';
import { Firebase as FirebaseContext, WoodenSpoon } from '@contexts';

import Ingredient from './view';

import './group.less';

const IngredientGroup = ({
	title,
	prefix,
	ingredients,
	quantity,
	unit,
	editable,
	onChange,
	onClick,
	dndProps,
	multiplier
}) => {
	const { getDbRef } = useContext(FirebaseContext);
	const { units } = useContext(WoodenSpoon);
	const isGroup = (title && (title !== '_' || prefix));

	let inner;

	if (!editable) {
		inner = <ul>{
			ingredients.map((i, idx) => (
				<Ingredient
					id={i.ingredient?.id || null}
					key={`${idx}-${i.ingredient?.id}`}
					quantity={i.quantity}
					unit={i.unit}
					preparation={i.preparation}
					editable={false}
					group={title}
					multiplier={multiplier  ?? 1}
				/>
			))
		}</ul>
	} else {
		inner = <DragDropContext onDragEnd={(e) => {
			onChange({
				group: title,
				items: arrayMove(ingredients, e.source.index, e.destination.index)
			})
		}}>
			<Droppable droppableId="ingredients">{
				(provided, snapshot) => (
					<ul
						{...provided.droppableProps}
						ref={provided.innerRef}
					>{
							ingredients.map((i, idx) => (
								<Draggable draggableId={`ingredient-${idx}`} key={idx} index={idx}>{
									(provided, snapshot) => (
										<Ingredient
											id={i.ingredient?.id || null}
											dndProps={provided}
											key={idx}
											quantity={i.quantity}
											unit={i.unit}
											preparation={i.preparation}
											editable={editable}
											group={title}
											multiplier={multiplier ?? 1}
											onChange={(data) => {
												onChange({
													group: title,
													items: ingredients.map((i2, idx2) => (
														idx === idx2 ? data : i2
													)).map((i3) => {
														return {
															...i3,
															quantity: parseFloat(i3.quantity),
															unit: getDbRef('unit', i3.unit),
															ingredient: getDbRef('ingredients', i3.ingredient),
														}
													})
												});
											}}
											onDelete={
												() => onChange({
													group: title,
													items: ingredients.filter((_i, _idx) => (
														idx !== _idx
													))
												})
											}
										/>
									)
								}</Draggable>
							))
						}
						{provided.placeholder}
					</ul>
				)}
			</Droppable>
		</DragDropContext>
	}

	const displayTitle = `${getQuantity(quantity, units[unit], 1)} ${ prefix ? prefix : '' }${ Boolean(prefix) && title !== '_' ? ': ' : ''}${ title !== '_' ? title : ''}`;

	return <li className={isGroup ? 'group ingredient-group' : 'default-group'} ref={dndProps?.innerRef} {...dndProps?.draggableProps}>
		{isGroup && <h2>
			{Boolean(dndProps) && <div  {...dndProps?.dragHandleProps}><DragIndicatorIcon /></div>}
			{onClick
				? (
					<span onClick={ onClick }>
						{ displayTitle }
					</span>
				) : displayTitle
			}
		</h2>}
		{inner}
	</li>;
}

export default IngredientGroup;
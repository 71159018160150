import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';

import { Recipe as RecipeContext } from '@contexts';

import SelectOrCreate from '@components/SelectOrCreate';
import { Select as SelectUnit } from '@components/WoodenSpoon/Unit';
import { Select as SelectElement } from '@components/WoodenSpoon/Element';

import './edit.less'
import { useMediaVariants } from 'hooks/mediaVariants';

const IngredientDialog = ({
	open,
	onClose,
	quantity,
	unit,
	ingredient,
	preparation,
	group,
	onSave,
	onDelete,
}) => {
	const [data, setData] = useState({});
	const recipe = useContext(RecipeContext);
	const { isMobile } = useMediaVariants();

	useEffect(() => {
		setData({
			quantity: quantity || '',
			unit: unit || '',
			ingredient: ingredient || '',
			preparation: preparation || '',
			group: group || '',
		});
	}, [setData, quantity, unit, ingredient, preparation, open]);

	return (
		<Dialog
			className="ingredient-edit"
			open={open}
			onClose={onClose}
			onKeyPress={(event) => { if (event.key === 'Enter') { onSave(data); onClose(); } }}
			fullScreen={ isMobile }
		>
			<DialogContent style={{ alignContent: 'start' }}>
				<TextField
					className="quantity"
					inputProps={{ type: 'number' }}
					value={ data.quantity }
					onChange={ (e) => setData({
						...data,
						quantity: Number(e.target.value)
					})}
					label="Quantity"
					variant="outlined"
				/>
				<SelectUnit
					value={ data.unit }
					onChange={ (val) => {
						setData({
							...data,
							unit: val
						})
					}}
				/>
				<SelectElement
					value={data.ingredient}
					className="ingredient-select"
					onChange={ (val) => {
						setData({
							...data,
							ingredient: val
						})
					}}
				/>
				<TextField
					value={ data.preparation }
					onChange={ (e) => { setData({
						...data,
						preparation: e.target.value
					})}}
					label="Preparation"
					variant="outlined"
					className="preparation"
					fullWidth
				/>
				<SelectOrCreate
					label="Group"
					value={data.group === '_' ? '' : data.group}
					fullWidth
					onChange={(newGroup) => {
						setData({ ...data, group: newGroup });
					}}
					options={
						Object.fromEntries(recipe?.ingredients?.map(({ group }) => group).filter((g) => g !== '_').map((g) => [g, g]) ?? [])
					}
				/>
			</DialogContent>
			<DialogActions>
				{onDelete && (
					<Button className="error" variant="contained" onClick={ onDelete }>Delete</Button>
				) }
				<Button onClick={onClose}>Cancel</Button>
				<Button color="primary" variant="contained"
					onClick={() => {
						onSave(data);
						onClose();
					}}
				>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IngredientDialog;
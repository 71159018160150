import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import {
	View as Step,
} from '@components/WoodenSpoon/Step';

import './list.less';

export default function StepList({ steps, editable, onChange }) {
	if (!steps) return null;
	
	if (
		!editable ||
		(steps.length === 1)
	) {
		return <ol className={`step-list ${editable ? 'editable' : ''}`}>{
			steps.map((step, idx) => (
				<Step
					key={idx}
					step={step}
					editable={editable}
					onChange={(data) => {
						onChange(
							steps.map((oStep, oIdx) =>
								idx === oIdx ? data : oStep
							)
						)
					}}
					onDelete={
						() => {
							onChange(steps.filter((_, oIdx) => oIdx !== idx));
						}
					}
				/>
			))
		}</ol>
	} else {
		return (
			<DragDropContext onDragEnd={(e) => {
				onChange(arrayMove(steps, e.source.index, e.destination.index));
			}}>
				<Droppable droppableId="ingredient-steps" renderClone={(provided, snapshot, rubric) => (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						{steps[rubric.source.index].text}
					</div>
				)}>{
						(provided, snapshot) => (
							<ol
								{...provided.droppableProps}
								className={`step-list ${editable ? 'editable' : ''}`}
								ref={provided.innerRef}
							>{
									steps.map((step, idx) => {
										return (
											<Draggable draggableId={`step-${idx}`} key={idx} index={idx}>{
												(provided, snapshot) => {
													return (
														<Step
															key={idx}
															step={step}
															editable={editable}
															dndProps={provided}
															onChange={(data) =>
																onChange(steps.map((oStep, oIdx) =>
																	idx === oIdx ? data : oStep))
															}
															onDelete={
																() => {
																	onChange(steps.filter((_, oIdx) => oIdx !== idx));
																}
															}
														/>
													)
												}
											}</Draggable>
										)
									})
								}
								{provided.placeholder}
							</ol>
						)
					}</Droppable>
			</DragDropContext>
		)
	}
}
import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { Login } from '@components/Firebase';

import Recipe from '@components/WoodenSpoon/Recipe';

const RecipeViewPage = () => {
    const { recipeId } = useParams();
    return <Recipe link={ recipeId } />
}

const Routes = () => (
    <Switch>
        <Route path="/login" component={ Login } />
        <Route path="/recipe/:recipeId" component={ RecipeViewPage } />
    </Switch>
);

export default Routes;
import { getDocs, getDocsFromCache, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';

const query = ({ 
	query: _queryset,
	transform,
	setData
}) => {
	const queryset = Array.isArray(_queryset) ? _queryset : [_queryset];
	const clean = queryset.map((q) => {
		return onSnapshot(q, { source:'cache'}, (snapshot) => {
			let newData = {}
			snapshot.forEach(async (doc) => {
				newData[doc.id] = doc.data();
			})
			if (transform) {
				newData = transform(newData);
			}
			setData((prev) => {
				return {
					...(prev || {}),
					...(newData || {}),
				};
			});
		});
	});
	return () => { clean.forEach((c) => c()) }
};

const processChanges = (key, newData, removed, transform) => {
	const rawData = {
		[key]: newData
	};
	if (transform && transform[key]) {
		Object.entries(transform[key]).forEach(([newKey, t]) => {
			rawData[newKey] = t({ ...rawData });
		});
	}
	return (prev) => {
		const data = Object.fromEntries(
			[...new Set([
				...Object.keys(prev || {}),
				...Object.keys(rawData || {})
			])]
				.map((key) => {
					if (Array.isArray(rawData[key])) {
						return [
							key, [
								...(prev[key] ?? []),
								...(rawData[key] ?? [])
							].filter((v, i, self) => self.indexOf(v) === i)
						];
					} else if (typeof rawData[key] === 'object') {
						return (
							[key, Object.fromEntries(
								Object.entries({
									...(prev[key] || {}),
									...(rawData[key] || {}),
								})
									.filter(([key, obj]) => !removed.includes(key))
							)]
						)
					}
					return [key, newData[key] ?? prev[key]];
				})
		)
		return data;
	}
}

const QueryListener = ({
	context: Context,
	queries: _queries,
	transform,
	children,
}) => {
	const [ data, setData ] = useState({});
	const originalData = useContext(Context);
	useEffect(() => {
		const unsubs = Object.entries(_queries).map(([key, query]) => {
			const queries = Array.isArray(query) ? query : [query];
			return queries.filter((v) => Boolean(v)).map((query) => {
				return onSnapshot(query, (qs) => {
					const removed = [];
					const newData = {}
					qs.docChanges().forEach((change) => {
						if (change.type === 'removed') {
							removed.push(change.doc.id);
						} else {
							newData[change.doc.id] = change.doc.data()
						}
					});
					setData(processChanges(key, newData, removed, transform));
				})
			})
		});
		return () => {
			unsubs.forEach((uns) => {
				uns.forEach((u) => u());
			})
		}
	}, [_queries]);

	return (
		<Context.Provider value={{ ...originalData, ...data }}>
			{ children }
		</Context.Provider>
	);
};

export default QueryListener;
export { query, QueryListener };
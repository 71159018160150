import React, { useContext } from 'react';

import { WoodenSpoon as WoodenSpoonContext } from '@contexts';
import SelectOrCreate from '@components/SelectOrCreate';

import UnitDialog from './edit';

const SelectUnit = ({
	value,
	onChange,
	label,
}) => {
	const { units } = useContext(WoodenSpoonContext);
	
	return (
		<SelectOrCreate
		 	options={ units }
			label={ label || 'Unit' }
			className="unit"
			value={ units[value]?.name ?? '' }
			onChange={(val) => { console.log(val); onChange(val) } }
			addDialog={ (props) => {
				const newProps = {
					...props,
					data:{
						...props.data,
						name: props.data.value
					}
				}
				return <UnitDialog { ...newProps } />
			}}
		/>
	);
}

export default SelectUnit
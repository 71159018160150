import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Switch, TextField } from '@material-ui/core';

import { Firebase as FirebaseContext } from '@contexts';

import './edit.css'
import { addDoc } from 'firebase/firestore';

const UnitDialog = ({
	open,
	onClose,
	data: initialData,
}) => {
	const { collection } = useContext(FirebaseContext);
	const [ data, setData ] = useState(initialData);
	const [ volume, setVolume ] = useState(false);

	useEffect(() => {
		setData(initialData)
	}, [initialData]);

	return (
		<Dialog
			className="unit-dialog"
			open={ open }
			onClose={ onClose }
		>
			<DialogContent>
				<TextField
					fullWidth
					value={ data.name || '' }
					onChange={ (e) => setData({
						...data,
						name: e.target.value
					})}
					label="Name"
					className="name"
					variant="outlined"
				/>
				<TextField
					fullWidth
					value={ data.symbol || '' }
					onChange={ (e) => setData({
						...data,
						symbol: e.target.value
					})}
					label="Symbol"
					className="symbol"
					variant="outlined"
				/>
				<div className="size">
					<Switch
						checked={ volume }
						onClick={ () => setVolume(!volume) }
					/>
					<TextField
						value={ data.size || '' }
						onChange={ (e) => setData({
							...data,
							size: e.target.value
						})}
						label={ volume ? 'Mililitres' : 'Grams' }
						variant="outlined"
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					onClick={ async () => {
						const newEl = addDoc(collection('units'), data);
						onClose(newEl.id);
					}}
				>Create</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UnitDialog;

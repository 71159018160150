import React, { useContext } from 'react';

import { WoodenSpoon as WoodenSpoonContext } from '@contexts';
import SelectOrCreate from '@components/SelectOrCreate';

import ElementDialog from './edit';

const SelectUnit = ({
	value,
	onChange,
	label,
	className
}) => {
	const { ingredients, recipes } = useContext(WoodenSpoonContext);

	return (
		<SelectOrCreate
			options={{
				...ingredients,
				...recipes
			}}
			label={ label || 'Ingredient' }
			value={ value }
			onChange={onChange}
			className={className}
			addDialog={ (props) => {
				const newProps = {
					...props,
					data:{
						...props.data,
						name: props.data.value
					}
				}
				return <ElementDialog { ...newProps } />
			}}
		/>
	);
}

export default SelectUnit
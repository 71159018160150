import React from 'react';
import { IconButton } from '@material-ui/core';
import { signOut } from 'firebase/auth';
import { Logout as LogoutIcon } from '@mui/icons-material';

export const Logout = () => (
    <IconButton
        onClick={signOut}
    >
        <LogoutIcon />
    </IconButton>
)

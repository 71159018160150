import React, { useContext, useEffect, useState } from 'react';
import { deleteDoc, setDoc } from 'firebase/firestore';

import {
	IconButton, Card, CardContent, CardMedia, CardHeader, Tooltip, Button, Grid, Typography, TextField
} from '@material-ui/core'
import { Edit as EditIcon, Menu as DetailsIcon } from '@material-ui/icons'

import {
	Auth as AuthContext,
	Firebase as FirebaseContext,
	Recipe as RecipeContext,
	WoodenSpoon as WoodenSpoonContext,
} from '@contexts';

import {
	Edit as IngredientEditDialog,
	List as IngredientList,
} from '@components/WoodenSpoon/Ingredient';

import {
	List as StepList,
	Edit as StepDialog
} from '@components/WoodenSpoon/Step';

import RecipeEditDialog from './edit';

import './view.less';

const Recipe = ({
	link,
}) => {
	const { user } = useContext(AuthContext);
	const { doc, getDbRef } = useContext(FirebaseContext);
    const { recipes, units } = useContext(WoodenSpoonContext);
	const [ recipe, setRecipe ] = useState(null);
	const [ details, setDetails] = useState(false);
	const [ editing, setEditing ] = useState(false);
	const [addingIngredient, setAddingIngredient] = useState(false);
	const [addingStep, setAddingStep] = useState(false);
	const [editServes, setEditServes] = useState(false);
	const [servings, setServings] = useState(recipes[recipe]?.servings ?? 1);

	const update = (data) => {
		const { id, ...strippedData } = data;
		setDoc(doc('recipes', link), { ...strippedData });
	}

	const editable = recipe?.owner === user?.uid;

	useEffect(() => {
		if (recipes) {
			if (link && recipes[link]) {
				setRecipe(recipes[link]);
			}
		}
	}, [recipes, link]);

	useEffect(() => {
		if (recipe?.servings) setServings(recipe.servings)
	}, [recipe]);

	if (!recipe) return null;

	return (<RecipeContext.Provider value={ recipe }>
		<Card
			className="recipe"
			elevation={12}
			onClick={ () => setEditServes(false) }
		>
			<CardHeader
				title={ 
					recipe.source ? (
						<a href={ recipe.source } target="_blank" rel="noopener noreferrer">{ recipe.name }</a>
					) : (
						recipe.name
					)
				}
				action={
					recipe.owner === user?.uid && <>
						<Tooltip title="Edit Recipe">
							<IconButton onClick={ () => setDetails(true) }>
								<DetailsIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Edit Recipe">
							<IconButton onClick={ () => setEditing(!editing) }>
								<EditIcon />
							</IconButton>
						</Tooltip>
					</>
				}
			/>
			<div className="body">
				<div className="media">
					<CardMedia image={recipe.image ? recipe.image : '/image-placeholder.svg'} style={{ flex: '33%' }} />
					<div className="servings-wrapper"
						onClick={(e) => { e.stopPropagation(); setEditServes(true) } }
					>
						<div className="servings">
							Makes
							{' '}
							{editServes ?
								<TextField
									type="number"
									value={servings}
									onChange={(e) => setServings(parseInt(e.target.value))}
									style={{ width: '2em' }}
								/>
								: servings
							}
							{' '}
							{units[recipe.servingsUnit]?.name ?? ''}
						</div>
					</div>
				</div>
				<CardContent className="content">
					<Grid container spacing={4}>
						<Grid item xs={12} sm={5}>
								<Grid container spacing={2} className="ingredients">
									<Grid item xs={12}>
										<Typography variant="h5">Ingredients</Typography>
									</Grid>
								<Grid item xs={12}>
									<IngredientList
										multiplier={servings / recipe.servings}
										ingredients={recipe?.ingredients}
										editable={editing && editable}
										onChange={(data) => update({...recipe, ingredients: data}) }
									/>
								</Grid>
								<Grid item xs={12}>{editable && editing && (
									<Button variant="outlined" onClick={() => setAddingIngredient(true)} fullWidth>
										+ Ingredient
									</Button>
								)}</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={7} className="steps">
							<Grid container spacing={2} className="mobileOnly">
								<Grid item xs={12}>
									<Typography variant="h5">Method</Typography>
								</Grid>
								<Grid item xs={12}>
									<StepList
										steps={recipe?.steps}
										editable={editing && editable}
										onChange={(data) => update({
											...recipe,
											steps: data
										})}
									/>
								</Grid>
								{ editable && editing &&  (
									<Grid item xs={12}>
										<Button onClick={ () => setAddingStep(true) } variant="outlined" fullWidth>
											+ Step
										</Button>						
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</div>
		</Card>
		<RecipeEditDialog
			open={ details }
			recipe={ link }
			onClose={ () => setDetails(false) }
			onSave={(data) => {
				update({
					...recipe,
					...data,
				});
			}}
			onDelete={() => {
				deleteDoc(doc('recipes', link));
			}}
		/>
		<IngredientEditDialog
			open={ addingIngredient }
			onClose={ () => setAddingIngredient(false) }
			onSave={(data) => {
				const { group: _group, unit, ingredient, ...extraIngredient } = data;
				const targetGroup = _group ? _group : '_';
				const newIngredient = {
					unit: getDbRef('unit', unit),
					ingredient: getDbRef('ingredients', ingredient),
					...extraIngredient
				}

				let newIngredients = recipe.ingredients;
				const groupExists = recipe.ingredients?.some(({ group }) => group === targetGroup)
				if (groupExists) {
					newIngredients = recipe.ingredients?.map(({ group, items }) =>
						group === targetGroup ? {
							group,
							items: [
								...items,
								newIngredient
							]
						} : { group, items }
					)
				} else {
					newIngredients = [
						...(newIngredients ?? []),
						{
							group: targetGroup,
							items: [newIngredient],
						}
					];
				}
				
				update({
					...recipe,
					ingredients: newIngredients
				});
			}}
		/>
		<StepDialog
			open={ addingStep }
			components={
				(recipe.ingredients || [])
					.reduce((acc, val) => [
						...acc,
						...val.items
					], [])
					.map((i) => recipes[i.ingredient?.id] ? i.ingredient?.id : null)
					.filter(Boolean)
			}
			onClose={ () => setAddingStep(false) }
			onSave={ (data) => {
				update({
					...recipe,
					steps: [
						...(recipe.steps || []),
						{
							...data,
							component: data.component ? doc('elements', data.component) : null
						}
					]
				});
			}}
		/>
	</RecipeContext.Provider>
	)
};

export default Recipe;
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const SelectOrCreate = ({
	noCreate,
	options,
	label,
	value,
	onChange,
	addDialog,
	onCreate,
	...props
}) => {
	const [ adding, setAdding ] = useState(false);
	const [ newValue, setNewValue ] = useState();
	const [dialogData, setDialogData] = useState({});
	
	if (!options) return null;

	return (<>
		<Autocomplete
			selectOnFocus clearOnBlur handleHomeEndKeys freeSolo
			value={ newValue ? newValue : (value ? value : '') }
			options={ Object.entries(options).map(([key, opt]) => ({ key: key, ...(typeof(opt) === 'string' ? { name: opt } : opt)})) }
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				if (!noCreate && params.inputValue !== '') {
					filtered.push({
						inputValue: params.inputValue,
						name: `Create "${params.inputValue}"`,
					});
				}
				return filtered;
			}}
			getOptionLabel={(opt) => opt.name ?? opt.key ?? opt}
			style={props.fullWidth ? {} : { width: 300 }}
			renderInput={(inputParams) => (
				<TextField
					{ ...inputParams }
					label={ label }
					variant="outlined"
				/>
			)}
			onChange={(e, newValue) => {
				if (addDialog && newValue && newValue.inputValue) {
					setAdding(true);
					setDialogData({
						value: newValue.inputValue,
					});
				} else {
					if (typeof newValue === 'string') {
						onChange(newValue);
					} else {
						if (onCreate && newValue?.inputValue) onCreate(newValue.inputValue);
						onChange(newValue?.inputValue ?? newValue?.key ?? '');
					}
				}
				setNewValue(null);
			}}
			{ ...props }
		/>
		{ addDialog && addDialog({
			open: adding,
			data: dialogData,
			onClose: (id) => {
				setAdding(false);
				setNewValue(id);
			}
		})}
	</>)
}

export default SelectOrCreate;
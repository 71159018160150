import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import debounce from 'debounce';

import { Card, CardContent, CardMedia, } from '@material-ui/core'

import ThumbMenu from '@components/ThumbMenu';
import {
	WoodenSpoon as WoodenSpoonContext,
	State as StateContext,
} from '@contexts';

const RecipeNav = ({
	open,
	onChange
}) => {
	const { elements, recipes } = useContext(WoodenSpoonContext);
	const { search, filters } = useContext(StateContext);
	const [results, setResults] = useState([]);
	
	const refreshSearch = useCallback(
		debounce((recipes, _elements, search, filters) => {
			const searchCheck = (q) => q?.toLowerCase().includes((search || '').toLowerCase());

			const filtered = Object
				.entries(recipes || {})
				.filter(([id, recipe]) => {
					return (
						(filters.length === 0 || filters.every((f) => (recipe.tags ?? []).includes(f))) &&
						(
							searchCheck(recipe.name) ||
							(recipe.ingredients || [])
								.reduce((acc, val) => [...acc, ...val.items], [])
								.filter((i) => searchCheck(i.ingredient?.id))
								.length > 0
						)
					)
				});
			setResults(filtered);
		}, 250), []
	);

	useEffect(() => {
		refreshSearch(recipes, elements, search, filters);
	}, [refreshSearch, recipes, elements, search, filters]);

	if (!recipes) return null;

	return (
		<ThumbMenu open={ open } onChange={ onChange }>
			{ results
				.map(([id, recipe]) => (
					<Link to={ '/recipe/' + (recipe.link ? recipe.link : id) } key={ id }>
						<Card elevation={ 12 }>
							<CardMedia image={ recipe.image ?? '/image-placeholder.svg' } />
							<CardContent>
								<h2>{ recipe.name }</h2>
							</CardContent>
						</Card>
					</Link>
				))
			}
		</ThumbMenu>
	);
};

export default RecipeNav;
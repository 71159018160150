import React from 'react';

const State = React.createContext({
    search: '',
    setSearch: () => { },
    filters: [],
    setFilters: () => { },
    sideNavOpen: false,
    setSideNavOpen: () => { }
});

export default State;
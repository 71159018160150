
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import {
	Group as IngredientGroup
} from '@components/WoodenSpoon/Ingredient';

import './list.less'

export default function List({ ingredients, editable, multiplier, onChange }) {
	if (!ingredients) return null;

	if (
		!editable ||
		ingredients.reduce((acc, val) => acc + val.items.length, 0) === 0 ||
		(ingredients.length === 1 && ingredients[0].title === '_')
	) {
		return <ul className="ingredients-list">{
			ingredients.map(({ group, items }, idx) => (
				<IngredientGroup
					key={group}
					title={group}
					ingredients={items}
					editable={editable}
					multiplier={ multiplier ?? 1}
					onChange={(data) => {
						onChange(ingredients.map((oGroup, oIdx) => oIdx === idx ? data : oGroup))
					}}
				/>
			))
		}</ul>
	}

	return (
		<DragDropContext onDragEnd={(e) => {
			onChange(arrayMove(ingredients, e.source.index, e.destination.index))
		}}>
			<Droppable droppableId="ingredient-groups" renderClone={(provided, snapshot, rubric) => (
				<div
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
				>
					{ingredients[rubric.source.index].name}
				</div>
			)}>{
					(provided, snapshot) => (
						<ul
							{...provided.droppableProps}
							className="ingredients-list"
							ref={provided.innerRef}
						>{
								ingredients.map(({ group, items }, idx) => {
									return (
										<Draggable draggableId={`ingredient-group-${idx}`} key={idx} index={idx}>{
											(provided, snapshot) => {
												return (
													<IngredientGroup
														key={group}
														title={group}
														ingredients={items}
														editable={editable}
														dndProps={provided}
														multiplier={multiplier}
														onChange={(data) => onChange(ingredients.map((oGroup, oIdx) => oIdx === idx ? data : oGroup))}
													/>
												)
											}
										}</Draggable>
									)
								})
							}
							{provided.placeholder}
						</ul>
					)
				}</Droppable>
		</DragDropContext>
	)
}

import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';

import { Firebase as FirebaseContext } from '@contexts';

import './edit.css';
import { addDoc } from 'firebase/firestore';

const ElementDialog = ({
	open,
    onClose,
	data: initialData,
}) => {
	const [ data, setData ] = useState(initialData);
    const { collection } = useContext(FirebaseContext);

    useEffect(() => {
        setData(initialData)
    }, [initialData]);

	return (
		<Dialog
            className="element edit"
            open={ open }
            onClose={ onClose }
        >
			<DialogContent>
				<TextField
                    fullWidth
					value={ data.name || '' }
					onChange={ (e) => setData({
						...data,
						name: e.target.value
					})}
					label="Name"
                    className="name"
					variant="outlined"
				/>
				<TextField
					fullWidth
					value={ data.perUnit || '' }
					onChange={ (e) => setData({
						...data,
						perUnit: e.target.value
					})}
					label="Grams per Unit"
                    className="perUnit"
					variant="outlined"
				/>
				<TextField
					fullWidth
					value={ data.perMl || '' }
					onChange={ (e) => setData({
						...data,
						perMl: e.target.value
					})}
					label="Grams per Mililitre"
                    className="perMl"
					variant="outlined"
				/>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose }>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={ async () => {
                        const newEl = await addDoc(collection('ingredients'), data);
                        onClose(newEl.id);
                    }}
                >Create</Button>
            </DialogActions>
		</Dialog>
	);
}

export default ElementDialog;
